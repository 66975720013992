<template>
    <v-container class="fill-height text-center" fluid>
        <v-flex>
            <v-icon size="224">mdi-emoticon-sad-outline</v-icon>
            <h1 class="text-h2">404</h1>
            <h2 class="text-h5 my-4">Page not found</h2>
            <p>The page you are looking for doesn't exist or an other error occurred</p>
            <v-btn color="info" to="/home">Go Back</v-btn>
        </v-flex>
    </v-container>
</template>

<script>
export default {
    name: "ErrorNotFound"
};
</script>
