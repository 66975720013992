var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-crud',{attrs:{"icon":_vm.$t('invoices.items.icon'),"title":_vm.$t('invoices.items.title'),"stateModule":"invoiceItem","enable-add-option":_vm._hasPermissions('invoice_items.store'),"enable-update-option":_vm._hasPermissions('invoice_items.update'),"headers":_vm.headers,"default-valus-for-add":_vm.defaultValusForAdd},scopedSlots:_vm._u([{key:"addEditForm",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.product')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('products-field',{attrs:{"merchant":_vm.invoice.merchant_id,"outlined":"","error-messages":errors},model:{value:(item.product_id),callback:function ($$v) {_vm.$set(item, "product_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.product_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min_value:1","name":_vm.$t('attributes.count')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.count'),"error-messages":errors,"counter":""},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.count"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":500,"rows":"3","auto-grow":"","clearable":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }