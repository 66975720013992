<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="icon">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ title }}</div>

                    <div>
                        <!-- btn add new item  -->
                        <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem" v-if="enableAddOption"
                            ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <!-- more actions -->
                        <slot name="headerActions" />
                    </div>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base
                    :type="(item.id && 'UPDATE') || 'ADD'"
                    v-model="formDialog"
                    @canceled="formDialogClose"
                    @confirmed="validateAndSave"
                    :max-width="addEditFormWidth"
                >
                    <template #content>
                        <validation-observer ref="baseCrudForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <!-- here will set forms of add/update fields -->
                                <slot name="addEditForm" :item="item" />

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>

                    <template #footer>
                        <!-- here will set forms extra options -->
                        <slot name="addEditFormFooter" :item="item" />
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <!-- here will set form of search fields -->
                        <slot name="searchForm" />
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="headers"
                    :items="data.data"
                    ref="my_datatable"
                    :items-per-page="10000000"
                    hide-default-footer
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                        <template v-if="enableUpdateOption">
                            <v-btn v-if="!item.disallowEdit" x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>

                        <!-- here will be set options actions -->
                        <slot name="itemActions" :item="item" />
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination v-if="pagination" :data="pagination" @page-changed="filterData" />
                <!-- End pagination -->
                <!-- Start pagination -->

                <div v-else class="text-center px-4">
                    <v-row justify="space-between" align="center">
                        <v-col cols="auto" class="mb-3 mt-1">
                            <!-- show info about pagination (from - to - total ) -->
                            {{
                                $t("pagination.showing_count_of_items", {
                                    count: countFilteredItems,
                                    total: countItems
                                })
                            }}
                            <!--/ end show info about pagiantion -->
                        </v-col>
                    </v-row>
                </div>
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
export default {
    name: "BaseCrud",

    props: {
        icon: { type: String, required: true },
        title: { type: String, required: true },
        // (vuex) store module that will be use
        stateModule: { type: String, required: true },
        enableAddOption: { type: Boolean, default: false },
        enableUpdateOption: { type: Boolean, default: false },
        addEditFormWidth: { type: Number, default: 400 },
        pagination: { type: Object, default: null },
        // headers of datatable
        headers: { type: Array, required: true },
        // default values that will be set when user start to add new item
        defaultValusForAdd: { type: Object, default: () => {} },
        files: { type: Array, default: () => [] }, // files properties
        // boolean properties because FormData converts boolean to string , so we convert it to 0 and 1 to can manage it in backend
        booleans: { type: Array, default: () => [] },
        // properties that will skip (remove) in send request to backend
        skipProperties: { type: Array, default: () => [] }
    },

    data: function() {
        return {
            formDialog: false,
            isMounted: false,
            item: {},
            errors: {},
            search: Object.assign({}, this.$route.query)
        };
    },

    computed: {
        data: function() {
            return this.$store.state[this.stateModule].items;
        },

        countItems() {
            return (this.data.data && this.data.data.length) || 0;
        },

        /**
         * return count of items after filter datatable
         */
        countFilteredItems() {
            if (!this.isMounted) {
                return;
            }
            return (this.$refs.my_datatable && this.$refs.my_datatable._data.internalCurrentItems.length) || 0;
        },

        hasFiles() {
            for (const f of this.files) {
                if (this.item[f] instanceof File) {
                    return true;
                }
            }
            return false;
        }
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.baseCrudForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};
            let data = Object.assign(this.item);

            if (this.hasFiles) {
                // when data has files , then user FormData
                data = this.convertObjectToForm(data, this.files, this.booleans);
            }

            (data.id ? this.$store.dispatch(`${this.stateModule}/update`, data) : this.$store.dispatch(`${this.stateModule}/add`, data))
                .then(r => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$emit(data.id ? "updated-item" : "added-item", r.data.data); // triger event on item added or updated item
                })
                .catch(e => {
                    window.eeee = e;
                    console.log(e);
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.baseCrudForm.reset();
            this.item = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.item = { ...this.defaultValusForAdd };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.item = Object.assign({}, item);
            for (const f of this.files) {
                this.item[f] = null;
            }
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        },

        convertObjectToForm(data, files, booleans) {
            let form = new FormData();
            for (let e in data) {
                if (this.skipProperties.includes(e) || files.includes(e) || booleans.includes(e)) {
                    continue;
                }
                if (typeof data[e] === "object") {
                    for (let t in data[e]) {
                        form.set(`${e}[${t}]`, data[e][t]);
                    }
                } else {
                    form.set(e, data[e]);
                }
            }
            // set boolean properties , we conver it becuase in back-end receive it as string ('true' , 'false') , so we skip it by convert to number
            for (const e of booleans) {
                form.set(e, data[e] ? 1 : 0);
            }

            // set files
            for (const e of files) {
                if (typeof data[e] != "string" && data[e] != null) {
                    form.set(e, data[e], data[e].name);
                } else {
                    form.delete(e);
                }
            }
            form.id = data.id;
            return form;
        }
    }
};
</script>
