<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('invoices.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ isUpdate ? $t("options.update") : $t("options.add") }}</div>
                </div>

                <!-- Start Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <validation-observer ref="invoicesForm" v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                        <!-- show errors if there is errors when add/update item -->
                        <has-errors :errors="errors"></has-errors>

                        <v-row class="pt-5">
                            <!-- Start client information -->
                            <v-col cols="12">
                                <h3>{{ $t("attributes.client_information") }}</h3>
                            </v-col>

                            <template>
                                <v-col cols="12" sm="6">
                                    <validation-provider rules="required" :name="$t('attributes.name')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.client.name"
                                            :label="$t('attributes.name')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="64"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.code')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.client.code"
                                            :label="$t('attributes.code')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.client.phone"
                                            :label="$t('attributes.phone')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.nid')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.client.nid"
                                            :label="$t('attributes.nid')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.bank')" v-slot="{ errors }">
                                        <banks-field
                                            outlined
                                            v-model="invoice.client.bank_id"
                                            :error-messages="errors"
                                            @input="invoice.client.branch_id = null"
                                        />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.branch')" v-slot="{ errors }">
                                        <branches-field
                                            outlined
                                            v-model="invoice.client.branch_id"
                                            :error-messages="errors"
                                            :bank="invoice.client.bank_id"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required|min:6|max:32" :name="$t('attributes.account_number')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.client.account"
                                            :label="$t('attributes.account_number')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </template>
                            <!-- End merchant information -->

                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>

                            <!-- Start invoice information -->
                            <v-col cols="12">
                                <h3>{{ $t("attributes.invoice_information") }}</h3>
                            </v-col>

                            <template>
                                <v-col cols="12" lg="6">
                                    <validation-provider rules="required" :name="$t('attributes.merchant')" v-slot="{ errors }">
                                        <merchants-field v-model.trim="invoice.merchant_id" :error-messages="errors" outlined />
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.original_amount')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.original_amount"
                                            :label="$t('attributes.original_amount')"
                                            :error-messages="errors"
                                            counter
                                            type="number"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.amount')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.amount"
                                            :label="$t('attributes.amount')"
                                            :error-messages="errors"
                                            counter
                                            type="number"
                                            @input="calculateInstallmentValue"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.count_months')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.count_months"
                                            :label="$t('attributes.count_months')"
                                            :error-messages="errors"
                                            counter
                                            type="number"
                                            @input="calculateInstallmentValue"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider rules="required" :name="$t('attributes.installment_value')" v-slot="{ errors }">
                                        <v-text-field
                                            outlined
                                            v-model.trim="invoice.installment_value"
                                            :label="$t('attributes.installment_value')"
                                            :error-messages="errors"
                                            counter
                                            type="number"
                                            readonly
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <validation-provider
                                        rules="required|date|before_or_equal:today"
                                        :name="$t('attributes.date')"
                                        v-slot="{ errors }"
                                    >
                                        <date-field
                                            outlined
                                            v-model="invoice.date"
                                            :label="$t('attributes.date')"
                                            :error-messages="errors"
                                        ></date-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                                        <v-textarea
                                            outlined
                                            v-model.trim="invoice.note"
                                            :label="$t('attributes.note')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="1000"
                                            rows="2"
                                            auto-grow
                                            clearable
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>
                            </template>
                            <!-- End invoice information -->

                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>

                            <v-col cols="12">
                                <validation-provider
                                    :rules="`${isUpdate || 1 ? '' : 'required|'}size:20480|ext:pdf`"
                                    :name="$t('attributes.file')"
                                    v-slot="{ errors }"
                                >
                                    <v-file-input
                                        v-model="invoice.client.file"
                                        :label="$t('attributes.file')"
                                        :error-messages="errors"
                                        show-size
                                        counter
                                        outlined
                                    ></v-file-input>
                                </validation-provider>
                            </v-col>
                            <!-- End compnay information -->
                        </v-row>

                        <v-divider></v-divider>

                        <div class="text-end px-5 py-3">
                            <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                <!-- End Add form dialog -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        if (id) {
            store.dispatch("invoice/fetchById", id).then(() => {
                next();
            });
        } else {
            next();
        }
    },

    data: function() {
        return {
            invoice: {},
            errors: {}
        };
    },

    computed: {
        ...mapState({
            data: state => state.invoice.item
        }),

        isUpdate() {
            return !!this.invoice.id;
        }
    },

    created() {
        this.invoice = (this.$route.params.id && this.data) || {};

        if (!this.invoice.id) {
            this.invoice.client = {};
            this.invoice.date = _getCurrentDate();
        } else {
            this.invoice.client.file = null;
            this.invoice.client.bank_id = this.invoice.client.branch.bank_id;
        }
    },

    methods: {
        ...mapActions("invoice", ["add", "update"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.invoicesForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            let invoice = Object.assign({}, this.invoice);

            this.$store.state.app.loading = true;

            let data = new FormData();
            for (let e in invoice) {
                if (e == "client") {
                    for (let t in invoice.client) {
                        data.set(`client[${t}]`, invoice.client[t]);
                    }
                } else {
                    data.set(e, invoice[e]);
                }
            }
            if (typeof invoice.client.file != "string" && invoice.client.file != null) {
                data.set("client[file]", invoice.client.file, invoice.client.file.name);
            } else {
                data.delete("client[file]");
            }
            data.id = invoice.id;

            (data.id ? this.update(data) : this.add(data))
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$router.push({ path: `/invoices/${r.data.data.id}` });
                })
                .catch(e => {
                    this.errors = (e.response && e.response.data) || {};
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.invoice = {};
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.invoice = Object.assign({}, item);
        },

        calculateInstallmentValue() {
            this.invoice.installment_value = this.invoice.amount / this.invoice.count_months || 0;
        }
    }
};
</script>
