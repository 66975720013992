<template>
    <v-app>
        <!-- Start navbar -->
        <dashboard-navbar></dashboard-navbar>
        <!--/ End navbar -->

        <!-- Start sidebar -->
        <dashboard-sidebar></dashboard-sidebar>
        <!--/ End sidebar -->

        <!-- Start content -->
        <v-main>
            <v-container fluid class="py-8">
                <router-view></router-view>
            </v-container>
        </v-main>
        <!--/ End content -->
    </v-app>
</template>

<script>
import DashboardSidebar from "@/components/layouts/dashboard/Sidebar.vue";
import DashboardNavbar from "@/components/layouts/dashboard/Navbar.vue";

export default {
    name: "DashboardLayout",

    components: {
        DashboardSidebar,
        DashboardNavbar
    }
};
</script>

<style lang="scss">
.theme--light .v-main {
    background-color: #f5f5f5;
}
</style>
