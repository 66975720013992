import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import {
    required,
    required_if,
    email,
    min,
    max,
    numeric,
    min_value,
    max_value,
    digits,
    alpha_num,
    length,
    confirmed,
    ext,
    size
} from "vee-validate/dist/rules";
import validator from "validator";
import i18n from "./i18n";

/**
 * Register it globally
 */
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

/**
 *
 * Set validation interaction mode
 */
setInteractionMode("eager");

/**
 * ************************************
 *          Start exists rules        *
 * ************************************
 */

extend("required", {
    ...required,
    message: i18n.t("validation.required")
});

extend("required_if", {
    ...required_if,
    message: "The {_field_} field is required when {target} is present"
});

/* Start String rules */
extend("min", {
    ...min,
    message: i18n.t("validation.min")
});

extend("max", {
    ...max,
    message: i18n.t("validation.max")
});
/* End String rules */

extend("email", {
    ...email,
    message: i18n.t("validation.email")
});

/* Start Numeric rules */
extend("numeric", {
    ...numeric,
    message: i18n.t("validation.numeric")
});

extend("min_value", {
    ...min_value,
    message: i18n.t("validation.min_value")
});

extend("max_value", {
    ...max_value,
    message: i18n.t("validation.max_value")
});

extend("digits", {
    ...digits,
    message: i18n.t("validation.digits")
});

extend("alpha_num", {
    ...alpha_num,
    message: i18n.t("validation.alpha_num")
});

extend("length", {
    ...length,
    message: i18n.t("validation.length")
});

extend("confirmed", {
    ...confirmed,
    message: i18n.t("validation.confirmed")
});

extend("ext", {
    ...ext,
    message: i18n.t("validation.ext")
});

extend("size", {
    ...size,
    message: i18n.t("validation.size")
});

/* End Numeric rules */

/**
 * ************************************
 *          Start custom rules        *
 * ************************************
 */

/**
 * This validation to validate combobox or selct that has items are objects
 * Validate depneds on id , check if object has an id attribute
 */
extend("select_object", {
    validate(value) {
        if (typeof value !== "object") return false;

        return value.id || Object.keys(value).some(k => k.indexOf("_id"));
    },
    message: i18n.t("validation.select_object")
});

/**
 * Check if value is an Array.
 */
extend("array", {
    validate(value) {
        return Array.isArray(value);
    },
    message: "The selected {_field_} is invalid"
});

/**
 * Check if the string is an URL.
 */
extend("url", {
    validate(value) {
        return validator.isURL(value, { require_protocol: false });
    },
    message: i18n.t("validation.url")
});

/**
 * Check if string correct number format
 */

extend("phone", {
    validate(value) {
        return RegExp("09[1-5]{1}[0-9]{7}").test(value);
    },
    message: i18n.t("validation.phone")
});

/**
 * Check if string correct username format
 */

extend("username", {
    validate(value) {
        return RegExp("^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,30}[a-zA-Z0-9]$").test(value);
    },
    message: i18n.t("validation.username")
});

/**
 * Check if string correct arabic name format
 */

extend("arabic_name", {
    validate(value) {
        return RegExp(/^[\u0621-\u064A]([\s._-](?![\s._-])|[\u0621-\u064A]){0,30}[\u0621-\u064A]$/).test(value);
    },
    message: i18n.t("validation.arabic_name")
});

/**
 * Check if string correct english name format
 */

extend("english_name", {
    validate(value) {
        return RegExp(/^[a-zA-Z]([\s._-](?![\s._-])|[a-zA-Z]){1,30}[a-zA-Z]$/).test(value);
    },
    message: i18n.t("validation.english_name")
});

/**
 * Check if string correct national ID format
 */

extend("nid", {
    validate(value) {
        return RegExp("^[12]{2}[0-9]{10}$").test(value) && value.substring(1, 4) < new Date().getFullYear();
    },
    message: i18n.t("validation.nid")
});

/**
 * Check if the string is a Date.
 */
extend("date", {
    validate(value) {
        return validator.isDate(value);
    },
    message: "The date format is invalid"
});

/**
 * Check if the string is a Date before or equal another date.
 */
extend("before_or_equal", {
    params: ["date"],

    validate(value, { date }) {
        // The default second date in isAfter() function is now(today)
        if (date === "today") return !validator.isAfter(value);
        // if value of @date paramter is null ,that means we can not validate date , so skipp it
        if (!date) return true;
        return !validator.isAfter(value, date);
    },
    message: "The date must be before or equal {date}"
});

/**
 * Check if the string is a Date after or equal another date.
 */
extend("after_or_equal", {
    params: ["date"],

    validate(value, { date }) {
        // The default second date in isBefore() function is now(today)
        if (date == "today") return !validator.isBefore(value);
        // if value of @date paramter is null ,that means we can not validate date , so skipp it
        if (!date) return true;
        return !validator.isBefore(value, date);
    },
    message: "The date must be after or equal {date}"
});
