<template>
    <v-card class="mt-4 mx-auto">
        <div class="d-flex justify-space-between px-3">
            <router-link :to="iconTo" tag="button" :disabled="!iconTo">
                <v-sheet
                    class="v-sheet--offset"
                    rounded
                    :color="color || this.$store.state.app.color"
                    elevation="8"
                    :width="iconSize.width"
                    :height="iconSize.height"
                >
                    <v-icon :style="{ width: iconSize.width, height: iconSize.height }" dark large>{{ icon }}</v-icon>
                </v-sheet>
            </router-link>
            <v-card-text class="text-end">
                <slot name="title"></slot>
            </v-card-text>
        </div>

        <slot name="content"></slot>
    </v-card>
</template>

<script>
export default {
    name: "BaseCard",

    props: {
        color: { type: String, default: "" },
        icon: { type: String, required: true },
        iconTo: { type: [String, Object], default: "" },
        iconSize: {
            type: Object,
            default: function() {
                return { width: "90px", height: "90px" };
            }
        }
    }
};
</script>

<style>
.v-sheet--offset {
    top: -24px;
    position: relative;
}
</style>
