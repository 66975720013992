import BaseStoreModule from "@/plugins/utils/baseStoreModule";
import BaseStoreAdvancedModule from "@/plugins/utils/baseStoreAdvancedModule";
import BaseServiceApi from "@/plugins/utils/baseServiceApi";

export default {
    /**
     * Generate Store Module
     * @param {string} end_point end point of api
     * @param {boolean} namespaced module namespaced
     *
     * @returns
     */
    generateStoreModule(end_point, namespaced) {
        return new BaseStoreModule(new BaseServiceApi(end_point), namespaced);
    },

    /**
     * Generate Store Module
     * @param {string} end_point end point of api
     * @param {boolean} namespaced module namespaced
     *
     * @returns
     */
    generateStoreAdvancedModule(end_point, namespaced) {
        return new BaseStoreAdvancedModule(new BaseServiceApi(end_point), namespaced);
    }
};
