import axios from "axios";
import AuthApi from "@/apis/authApi";

/**
 * This module for authentication
 */
export default {
    namespaced: true,

    state: {
        user: {},
        key: "",
        token: ""
    },

    getters: {
        isLogged(state) {
            return !!state.token;
        }
    },

    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_KEY(state, key) {
            state.key = key;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        },
        CLEAR_TOKEN(state) {
            state.token = null;
            state.user = {};
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            axios.defaults.headers.common["Authorization"] = null;
        }
    },

    actions: {
        login({ commit }, credentials) {
            credentials.fcm_token = window.__fcm_token;
            return AuthApi.login(credentials).then(r => {
                commit("SET_USER", r.data.user);
                commit("SET_TOKEN", r.data.authorisation.token);
                commit("SET_KEY", r.data.key);
            });
        },

        logout({ commit }) {
            return AuthApi.logout({ fcm_token: window.__fcm_token }).then(() => {
                commit("CLEAR_TOKEN");
                location.reload();
            });
        },

        updateProfile({ commit }, user) {
            return AuthApi.updateProfile(user).then(r => {
                commit("SET_USER", r.data);
            });
        },

        updatePassword({ commit }, user) {
            return AuthApi.updatePassword(user);
        },

        checkIfUserAuthenticated({ commit }) {
            return new Promise(function(myResolve, myReject) {
                if (!localStorage.getItem("token")) return myResolve();

                commit("SET_TOKEN", localStorage.getItem("token"));
                return AuthApi.profile()
                    .then(r => {
                        commit("SET_USER", r.data.user);
                        commit("SET_TOKEN", r.data.authorisation.token);
                        commit("SET_KEY", r.data.key);
                        myResolve(r);
                    })
                    .catch(e => {
                        commit("CLEAR_TOKEN");
                        myReject(e);
                    });
            });
        }
    }
};
