var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-crud',{attrs:{"icon":_vm.$t('invoices.transactions.icon'),"title":_vm.$t('invoices.transactions.title'),"stateModule":"invoiceTransaction","enable-add-option":_vm._hasPermissions('invoice_transactions.store'),"enable-update-option":_vm._hasPermissions('invoice_transactions.update'),"headers":_vm.headers,"default-valus-for-add":_vm.defaultValusForAdd},on:{"added-item":_vm.addedItem,"updated-item":function($event){return _vm.$emit('updated-item', $event)}},scopedSlots:_vm._u([(_vm._hasPermissions('invoice_transactions.print'))?{key:"headerActions",fn:function(){return [_c('v-btn',{staticClass:"ms-1",attrs:{"fab":"","small":"","color":"blue-grey darken-3","dark":"","href":_vm.serverUrl(("/invoice_transactions/" + (_vm.invoice.id) + "?key=" + (_vm.$store.state.auth.key))),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]},proxy:true}:null,{key:"addEditForm",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"type":"number"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.date'),"error-messages":errors},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":500,"rows":"3","auto-grow":"","clearable":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})]}}],null,true)})],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }