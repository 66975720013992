<template>
    <v-container class="fill-height" fluid>
        <v-flex>
            <div class="mt-4 mx-auto" style="max-width: 350px;">
                <div class="text-center mb-8 pb-y5">
                    <v-icon size="175px">mdi-account-tie</v-icon>
                    <h3>{{ $t("auth.login_msg") }}</h3>
                </div>

                <validation-observer v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(signIn)">
                        <!-- show errors if there is errors  -->
                        <has-errors :errors="errors"></has-errors>

                        <validation-provider rules="required" :name="$t('attributes.username')" v-slot="{ errors }">
                            <v-text-field
                                prepend-icon="mdi-account"
                                v-model.trim="user.username"
                                :label="$t('attributes.username')"
                                :error-messages="errors"
                                :maxlength="64"
                                required
                            ></v-text-field>
                        </validation-provider>

                        <validation-provider rules="required|min:6|max:32" :name="$t('attributes.password')" v-slot="{ errors }">
                            <password-field v-model.trim="user.password" :label="$t('attributes.password')" :error-messages="errors" :max-length="32">
                            </password-field>
                        </validation-provider>

                        <v-btn type="submit" color="info" class="mt-4" block>{{ $t("auth.login") }}</v-btn>
                    </v-form>
                </validation-observer>
            </div>

            <!-- <v-card class="mt-4 mx-auto" width="350px">
                <v-sheet class="text-center" max-width="calc(100% - 32px)">
                    <v-icon size="200px">mdi-account-circle</v-icon>
                    <h3>{{ $t("auth.login") }}</h3>
                </v-sheet>

                <v-card-text class="pt-0 pb-10"> </v-card-text>
            </v-card> -->
        </v-flex>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",

    data: () => ({
        showPassword: false,
        errors: {}, // validation errors for add/update request
        user: { username: "", password: "" } // to save temp data to use it in add,update and delete
    }),

    methods: {
        ...mapActions("auth", ["login"]),

        /**
         * Add or update data in server depends on isUpdate property
         */
        signIn() {
            this.$store.state.app.loading = true;

            this.errors = {};

            this.login(this.user)
                .then(() => {
                    if (_hasPermissions("invoices.index")) {
                        this.$router.push("/invoices");
                    } else {
                        this.$router.push("profile");
                    }
                })
                .catch(r => {
                    window.r = r;
                    this.errors = r.response && r.response.data && r.response.data.errors;
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                });
        }
    }
};
</script>
