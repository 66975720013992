<template>
    <v-autocomplete
        :items="data.data || []"
        item-text="name"
        item-value="id"
        :value="value"
        :label="label || $t('attributes.city')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :chips="chips"
        :return-object="false"
    ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "CitiesField",

    props: {
        value: [String, Number],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        chips: { type: Boolean, default: false }
    },

    computed: {
        ...mapState({
            data: state => state.city.items
        })
    },
    created() {
        if (!this.data || !this.data.data) this.$store.dispatch("city/fetchAll");
    }
};
</script>
