<template>
    <v-dialog v-model="value" scrollable persistent :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <span class="headline">{{ titleLabel }}</span>
                <v-spacer></v-spacer>
                <v-btn fab x-small text @click="canceled"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="py-5 px-6 text--darken-3 grey--text">
                <slot name="content"></slot>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <slot name="footer"></slot>
                <v-spacer></v-spacer>
                <v-btn min-width="100px" @click="canceled">{{ $t("options.cancel") }}</v-btn>
                <v-btn min-width="100px" @click="$emit('confirmed')" :color="confirmColor" :loading="loading">{{ confirmButtonLabel }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogBase",

    props: {
        maxWidth: { type: [String, Number], default: "850px" },
        title: {
            required: false,
            type: String
        },
        type: {
            type: String,
            validator: value => ["ADD", "UPDATE", "DELETE"].includes(value)
        },
        confirmLabel: { type: String },
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },

    computed: {
        titleLabel() {
            return this.title || this.$t(`options.${this.type.toLowerCase()}`);
        },

        confirmButtonLabel() {
            return this.confirmLabel || this.$t(`options.${this.type.toLowerCase()}`);
        },

        confirmColor() {
            return this.type === "DELETE" ? "error" : this.$store.state.app.color;
        }
    },

    methods: {
        canceled() {
            this.$emit("canceled");
        }
    }
};
</script>
