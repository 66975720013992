<template>
    <v-menu v-model="showDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field
                v-model="internalValue"
                :label="label"
                :error-messages="errorMessages"
                readonly
                v-on="on"
                :clearable="clearable"
                :outlined="outlined"
            ></v-text-field>
        </template>
        <v-date-picker v-model="internalValue" @input="showDialog = false" :color="$store.state.app.color"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: "DateField",

    props: {
        value: { type: String },
        label: { type: String },
        color: { type: String, default: "" },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false }
    },

    data: function() {
        return {
            showDialog: false,
            internalValue: this.value
        };
    },

    watch: {
        /**
         * When props value change update internalValue
         */
        value(val) {
            this.internalValue = val;
        },

        /**
         * When internalValue change update value property for component
         */
        internalValue(val) {
            this.$emit("input", val);
        }
    }
};
</script>
