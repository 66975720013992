<template>
    <base-crud
        :icon="$t('roles.icon')"
        :title="$t('roles.title')"
        stateModule="role"
        :enable-add-option="_hasPermissions('roles.store')"
        :enable-update-option="_hasPermissions('roles.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
        :add-edit-form-width="1124"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col v-for="p in permissions" :key="p.id" cols="12" sm="6" md="4" lg="3">
                            <v-checkbox
                                hide-details
                                v-model="item.permissions"
                                :value="p.id"
                                :label="$t(`permissions.${p.name.replaceAll('.', '-')}`)"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>

        <template #addEditFormFooter="{ item }">
            <v-btn min-width="100px" color="info" @click="selectAll(item)">{{ $t("options.select_all") }}</v-btn>
            <v-btn min-width="100px" @click="item.permissions = []">{{ $t("options.unselect_all") }}</v-btn>
        </template>

        <!-- Start Search section-->
        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
            </v-row>
        </template>
        <!--/ End Search section-->
    </base-crud>
</template>

<script>
import { mapState } from "vuex";

export default {
    data: function() {
        return {
            search: {},
            headers: [
                {
                    text: this.$t("attributes.id"),
                    value: "id"
                },
                { text: this.$t("attributes.name"), value: "name", filter: value => filterContains(value, this.search.name) },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { permissions: [] }
        };
    },

    computed: {
        ...mapState({
            permissions: state => state.permission.items
        })
    },

    mounted() {
        this.$store.dispatch("permission/fetch");
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        /**
         * Select all permissions
         */
        selectAll(item) {
            item.permissions = this.permissions.map(p => p.id);
        }
    }
};
</script>
