var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('settings.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("settings.title")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"settingForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateSettings)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","outlined":"","maxlength":32},model:{value:(_vm.setting.phone),callback:function ($$v) {_vm.$set(_vm.setting, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('attributes.phone')) + "2"),"error-messages":errors,"counter":"","outlined":"","maxlength":32},model:{value:(_vm.setting.phone2),callback:function ($$v) {_vm.$set(_vm.setting, "phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.email'),"error-messages":errors,"counter":"","outlined":"","maxlength":75,"type":"email"},model:{value:(_vm.setting.email),callback:function ($$v) {_vm.$set(_vm.setting, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.address'),"error-messages":errors,"counter":"","outlined":"","maxlength":32},model:{value:(_vm.setting.address),callback:function ($$v) {_vm.$set(_vm.setting, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"setting.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"max-height":"150","contain":"","src":_vm.setting.logo_url}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"label":_vm.$t('attributes.logo'),"accept":"image/*","show-size":"","counter":"","outlined":""},model:{value:(_vm.setting.logo),callback:function ($$v) {_vm.$set(_vm.setting, "logo", $$v)},expression:"setting.logo"}})],1)],1),_c('v-divider'),_c('div',{staticClass:"text-end px-5 py-3"},[_c('v-btn',{attrs:{"color":"info","min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.update")))])],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }