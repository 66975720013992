var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-crud',{attrs:{"icon":_vm.$t('merchants.icon'),"title":_vm.$t('merchants.title'),"stateModule":"merchant","enable-add-option":_vm._hasPermissions('merchants.store'),"enable-update-option":_vm._hasPermissions('merchants.update'),"headers":_vm.headers,"default-valus-for-add":_vm.defaultValusForAdd,"add-edit-form-width":1024,"files":['file'],"booleans":['active'],"skip-properties":['city', 'type']},scopedSlots:_vm._u([{key:"addEditForm",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.type_of_merchant')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('merchants-types-field',{attrs:{"error-messages":errors},model:{value:(item.type_of_merchant_id),callback:function ($$v) {_vm.$set(item, "type_of_merchant_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.type_of_merchant_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cities-field',{attrs:{"error-messages":errors},model:{value:(item.city_id),callback:function ($$v) {_vm.$set(item, "city_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.city_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:64","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:64","name":_vm.$t('attributes.owner')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.owner'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(item.owner),callback:function ($$v) {_vm.$set(item, "owner", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.owner"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('attributes.state')},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"size:20480|ext:pdf","name":_vm.$t('attributes.file')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.$t('attributes.file'),"error-messages":errors,"show-size":"","counter":""},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.address'),"error-messages":errors,"counter":"","maxlength":1000,"rows":"2","auto-grow":"","clearable":""},model:{value:(item.address),callback:function ($$v) {_vm.$set(item, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.address"}})]}}],null,true)})],1)],1)]}},{key:"searchForm",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.id'),"clearable":"","type":"number"},model:{value:(_vm.search.id),callback:function ($$v) {_vm.$set(_vm.search, "id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"clearable":""},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('merchants-types-field',{attrs:{"clearable":""},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('cities-field',{attrs:{"chips":false,"clearable":""},model:{value:(_vm.search.city),callback:function ($$v) {_vm.$set(_vm.search, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.city"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('statuses-field',{attrs:{"label":_vm.$t('attributes.state'),"clearable":""},model:{value:(_vm.search.state),callback:function ($$v) {_vm.$set(_vm.search, "state", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.state"}})],1)],1)]},proxy:true},{key:"itemActions",fn:function(ref){
var item = ref.item;
return [(item.file_url)?_c('v-btn',{staticClass:"ms-1",attrs:{"x-small":"","fab":"","color":"secondary","dark":"","href":item.file_url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }