<template>
    <v-btn color="teal" class="white--text" type="button" @click="exportFile">
        <v-icon>mdi-file-excel-outline</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "ExportToExcel",

    props: {
        url: {
            type: String,
            required: true
        },
        name: String,
        filter: Object
    },

    computed: {
        params() {
            let _params = Object.assign({}, this.filter);
            _params.__export_to = "excel-file";
            return _params;
        }
    },

    methods: {
        exportFile() {
            let params = this.params;

            this.$store.state.app.loading = true;
            axios
                .get(this.url, { params, responseType: "blob" })
                .then(response => {
                    if (response.status === 204) {
                        Toast.error(this.$t("errors.no_result_found"));
                    } else {
                        this.downloadFile(response);
                    }
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                });
        },

        downloadFile(response) {
            const linkElement = document.createElement("a");
            linkElement.href = window.URL.createObjectURL(new Blob([response.data]));
            linkElement.setAttribute("download", `${this.name}_${moment().format("YYYY-MM-DD HH_mm_ss")}.xls`);
            document.body.appendChild(linkElement);
            linkElement.click();
        }
    }
};
</script>
