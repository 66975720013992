<template>
    <base-crud
        :icon="$t('users.icon')"
        :title="$t('users.title')"
        stateModule="user"
        :enable-add-option="_hasPermissions('users.store')"
        :enable-update-option="_hasPermissions('users.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
        :add-edit-form-width="1024"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.username')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.username"
                            :label="$t('attributes.username')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider :rules="(item.id ? '' : 'required|') + 'min:6|max:32'" :name="$t('attributes.password')" v-slot="{ errors }">
                        <password-field
                            v-model.trim="item.password"
                            :label="$t('attributes.password')"
                            :error-messages="errors"
                            :max-length="32"
                            :show-icon="false"
                        >
                        </password-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.role')" v-slot="{ errors }">
                        <roles-field v-model.trim="item.roles_ids" :error-messages="errors" />
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-switch v-model="item.active" :label="$t('attributes.state')"></v-switch>
                </v-col>
            </v-row>
        </template>

        <!-- Start Search section-->
        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.id" :label="$t('attributes.id')" clearable type="number" />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.username" :label="$t('attributes.username')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <roles-field v-model.trim="search.role" clearable :multiple="false" />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.state" :label="$t('attributes.state')" clearable />
                </v-col>
            </v-row>
        </template>
        <!--/ End Search section-->
    </base-crud>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                {
                    text: this.$t("attributes.id"),
                    value: "id",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(value, this.search.id) && _dtFilterArrayEqual(item.roles_ids, this.search.role);
                    }
                },
                { text: this.$t("attributes.name"), value: "name", filter: value => filterContains(value, this.search.name) },
                { text: this.$t("attributes.username"), value: "username", filter: value => filterContains(value, this.search.username) },
                /* {
                    text: this.$t("attributes.role"),
                    value: "roles",
                    filter: (value, search, item) => {
                        return _dtFilterArrayEqual(item.roles_ids, this.search.role);
                    }
                }, */
                { text: this.$t("attributes.state"), value: "active", filter: value => _dtFilterStatus(value, this.search.state) },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { active: true }
        };
    },
    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
