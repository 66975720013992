<!-- Start print links  -->
<template>
    <v-list-item link :color="color" :to="to" :active-class="activeClass">
        <v-list-item-action>
            <v-icon>{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>{{ label }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>
<!-- End print links  -->

<script>
export default {
    props: {
        label: String,
        icon: String,
        to: String,
        activeClass: String,
        color: { type: String, default: "white" }
    }
};
</script>
