/**
 * This module for Statistic
 */
import StatisticsApi from "@/apis/statisticsApi";

export default {
    namespaced: true,

    state: {
        statistics: {},
        charts: {}
    },

    mutations: {
        SET_STATISTICS(state, data) {
            state.statistics = data.statistics;
            state.aggregates = data.aggregates;
            state.charts = data.charts;
        }
    },

    actions: {
        fetchData({ commit }, params = {}) {
            return StatisticsApi.getData(params).then(r => {
                commit("SET_STATISTICS", r.data.data);
            });
        }
    }
};
