import Axios from "axios";

export default {
    namespaced: true,

    state: {
        item: {}
    },

    mutations: {
        SET_ITEM(state, item) {
            state.item = item;
        }
    },

    actions: {
        fetch({ commit, state }) {
            if (Object.keys(state.item).length) return;
            return Axios.get("settings").then(r => {
                commit("SET_ITEM", r.data.data);
            });
        },

        update({ commit }, data) {
            return Axios.post("settings", data).then(r => {
                Toast.success("The update was successful");
                commit("SET_ITEM", r.data.data);
            });
        }
    }
};
