import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

window.Toast = {
    notify: (text, icon) => {
        Vue.swal.fire({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: toast => {
                toast.addEventListener("mouseenter", VueSweetalert2.stopTimer);
                toast.addEventListener("mouseleave", VueSweetalert2.resumeTimer);
            },
            icon: icon,
            title: text
        });
    },

    success: text => Toast.notify(text, "success"),

    error: text => Toast.notify(text, "error")
};
