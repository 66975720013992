import i18n from "@/plugins/i18n.js";

/**
 * This module for app settings
 */
export default {
    namespaced: true,

    state: {
        layouts: {
            dashboard: {
                sidebar: {
                    show: true
                }
            }
        },

        color: "info",
        dark: false,
        loading: true,
        rtl: true
    },

    getters: {
        links: (state, _getters, _rootState, rootGetters) => {
            /* Start filter links depends on permissions */
            let links = [
                //{ label: i18n.t("home.title"), icon: i18n.t("home.icon"), url: "/home" },
                { label: i18n.t("invoices.title"), icon: i18n.t("invoices.icon"), url: "/invoices", permissions: ["invoices.index"] },
                { label: i18n.t("merchants.title"), icon: i18n.t("merchants.icon"), url: "/merchants", permissions: ["merchants.index"] },
                { label: i18n.t("products.title"), icon: i18n.t("products.icon"), url: "/products", permissions: ["products.index"] },
                {
                    label: i18n.t("reports.title"),
                    icon: i18n.t("reports.icon"),
                    group: true,
                    links: [
                        {
                            label: i18n.t("invoices.open.title"),
                            icon: i18n.t("invoices.open.icon"),
                            url: "/invoices-open",
                            permissions: ["invoices.open"]
                        }
                    ]
                },
                {
                    label: i18n.t("settings.title"),
                    icon: i18n.t("settings.icon"),
                    group: true,
                    links: [
                        {
                            label: i18n.t("merchants.types.title"),
                            icon: i18n.t("merchants.types.icon"),
                            url: "/type-merchants",
                            permissions: ["type_of_merchants.index"]
                        },
                        { label: i18n.t("cities.title"), icon: i18n.t("cities.icon"), url: "/cities", permissions: ["cities.index"] },
                        { label: i18n.t("banks.title"), icon: i18n.t("banks.icon"), url: "/banks", permissions: ["banks.index"] },
                        { label: i18n.t("branches.title"), icon: i18n.t("branches.icon"), url: "/branches", permissions: ["branches.index"] },
                        { label: i18n.t("profile.title"), icon: i18n.t("profile.icon"), url: "/profile" },
                        { label: i18n.t("users.title"), icon: i18n.t("users.icon"), url: "/users", permissions: ["users.index"] },
                        { label: i18n.t("roles.title"), icon: i18n.t("roles.icon"), url: "/roles", permissions: ["roles.index"] },
                        { label: i18n.t("settings.title"), icon: i18n.t("settings.icon"), url: "/settings", permissions: ["settings.index"] }
                    ]
                }
            ];

            let items = links.filter(link => {
                if (link.group) {
                    link.links = link.links.filter(sublink => !sublink.permissions || _hasPermissions(sublink.permissions));
                }

                if (!link.permissions) {
                    return true;
                }

                return _hasPermissions(link.permissions);
            });
            /* End filter links depends on permissions */

            /* remove empty links groups from links , then return links */
            return items.filter(i => !i.group || (i.links && i.links.length));
        }
    },

    actions: {
        changeLanguage({ state }, lang) {
            state.lang = this.$app.$i18n.changeLanguageTo(lang);
            state.rtl = this.$app.$i18n.t("direction") === "rtl";
            this.$app.$vuetify.rtl = state.rtl;
        }
    }
};
