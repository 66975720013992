var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-crud',{attrs:{"icon":_vm.$t('invoices.gurantors.icon'),"title":_vm.$t('invoices.gurantors.title'),"stateModule":"gurantor","enable-add-option":_vm._hasPermissions('gurantors.store'),"enable-update-option":_vm._hasPermissions('gurantors.update'),"headers":_vm.headers,"default-valus-for-add":_vm.defaultValusForAdd},scopedSlots:_vm._u([{key:"addEditForm",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.nid')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.nid'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(item.nid),callback:function ($$v) {_vm.$set(item, "nid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.nid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.bank')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('banks-field',{attrs:{"outlined":"","error-messages":errors},on:{"input":function($event){item.branch_id = null}},model:{value:(item.bank),callback:function ($$v) {_vm.$set(item, "bank", $$v)},expression:"item.bank"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('branches-field',{attrs:{"outlined":"","error-messages":errors,"bank":item.bank},model:{value:(item.branch_id),callback:function ($$v) {_vm.$set(item, "branch_id", $$v)},expression:"item.branch_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.account_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.account_number'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(item.account),callback:function ($$v) {_vm.$set(item, "account", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.account"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":500,"rows":"3","auto-grow":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }