<template>
    <div class="header-title-of-form">
        <v-text-field
            :label="label"
            disabled
            dense
            hide-details
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :prepend-inner-icon="prependInnerIcon"
            :append-inner-icon="appendInnerIcon"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    name: "Header",

    props: {
        label: { type: String, required: true },
        prependIcon: [String],
        appendIcon: [String],
        prependInnerIcon: [String],
        appendInnerIcon: [String]
    }
};
</script>

<style lang="scss">
.header-title-of-form {
    .theme--light.v-icon.v-icon.v-icon--disabled,
    .theme--light.v-label--is-disabled {
        color: rgba(0, 0, 0, 0.54) !important;
    }
}
</style>
