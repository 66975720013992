var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('invoices.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.isUpdate ? _vm.$t("options.update") : _vm.$t("options.add")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"invoicesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("attributes.client_information")))])]),[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(_vm.invoice.client.name),callback:function ($$v) {_vm.$set(_vm.invoice.client, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.client.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.code'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.invoice.client.code),callback:function ($$v) {_vm.$set(_vm.invoice.client, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.client.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.invoice.client.phone),callback:function ($$v) {_vm.$set(_vm.invoice.client, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.client.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.nid')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.nid'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.invoice.client.nid),callback:function ($$v) {_vm.$set(_vm.invoice.client, "nid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.client.nid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.bank')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('banks-field',{attrs:{"outlined":"","error-messages":errors},on:{"input":function($event){_vm.invoice.client.branch_id = null}},model:{value:(_vm.invoice.client.bank_id),callback:function ($$v) {_vm.$set(_vm.invoice.client, "bank_id", $$v)},expression:"invoice.client.bank_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('branches-field',{attrs:{"outlined":"","error-messages":errors,"bank":_vm.invoice.client.bank_id},model:{value:(_vm.invoice.client.branch_id),callback:function ($$v) {_vm.$set(_vm.invoice.client, "branch_id", $$v)},expression:"invoice.client.branch_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.account_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.account_number'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.invoice.client.account),callback:function ($$v) {_vm.$set(_vm.invoice.client, "account", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.client.account"}})]}}],null,true)})],1)],_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("attributes.invoice_information")))])]),[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.merchant')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('merchants-field',{attrs:{"error-messages":errors,"outlined":""},model:{value:(_vm.invoice.merchant_id),callback:function ($$v) {_vm.$set(_vm.invoice, "merchant_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.merchant_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.original_amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.original_amount'),"error-messages":errors,"counter":"","type":"number"},model:{value:(_vm.invoice.original_amount),callback:function ($$v) {_vm.$set(_vm.invoice, "original_amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.original_amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"counter":"","type":"number"},on:{"input":_vm.calculateInstallmentValue},model:{value:(_vm.invoice.amount),callback:function ($$v) {_vm.$set(_vm.invoice, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.count_months')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.count_months'),"error-messages":errors,"counter":"","type":"number"},on:{"input":_vm.calculateInstallmentValue},model:{value:(_vm.invoice.count_months),callback:function ($$v) {_vm.$set(_vm.invoice, "count_months", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.count_months"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.installment_value')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.installment_value'),"error-messages":errors,"counter":"","type":"number","readonly":""},model:{value:(_vm.invoice.installment_value),callback:function ($$v) {_vm.$set(_vm.invoice, "installment_value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.installment_value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.date'),"error-messages":errors},model:{value:(_vm.invoice.date),callback:function ($$v) {_vm.$set(_vm.invoice, "date", $$v)},expression:"invoice.date"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":1000,"rows":"2","auto-grow":"","clearable":""},model:{value:(_vm.invoice.note),callback:function ($$v) {_vm.$set(_vm.invoice, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"invoice.note"}})]}}],null,true)})],1)],_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":((_vm.isUpdate || 1 ? '' : 'required|') + "size:20480|ext:pdf"),"name":_vm.$t('attributes.file')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.$t('attributes.file'),"error-messages":errors,"show-size":"","counter":"","outlined":""},model:{value:(_vm.invoice.client.file),callback:function ($$v) {_vm.$set(_vm.invoice.client, "file", $$v)},expression:"invoice.client.file"}})]}}],null,true)})],1)],2),_c('v-divider'),_c('div',{staticClass:"text-end px-5 py-3"},[_c('v-btn',{attrs:{"color":"info","min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.save")))])],1)],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }