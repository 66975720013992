<template>
    <v-autocomplete
        :items="products"
        item-text="name"
        item-value="id"
        :value="value"
        :label="label || $t('attributes.product')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :chips="chips"
        :return-object="false"
    ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ProductsField",

    props: {
        value: [String, Array, Object, Number],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        dense: { type: Boolean, default: false },
        chips: { type: Boolean, default: false },
        merchant: [Object, Number]
    },

    computed: {
        ...mapState({
            data: state => state.product.items.data
        }),

        products() {
            let merchant_id = (this.merchant && this.merchant.id) || this.merchant;
            if (!merchant_id) return [];
            return this.data && this.data.filter(i => i.merchant_id == merchant_id);
        }
    },
    created() {
        if (!this.data || !this.data.data) this.$store.dispatch("product/fetchAll");
    }
};
</script>
