/**
 * This file to set routes for dashboard
 */

/**
 * Properties for these routes
 */
const meta = {
    requiresAuth: true /* route requires authentication */,
    layout: "dashboard-layout" /* route uses dashboard-layout */
};

const routes = [
    /* {
        path: "/Home",
        component: require(`@/views/dashboard/Home.vue`).default,
        meta,
        alias: "/"
    }, */
    {
        path: "/profile",
        component: require(`@/views/dashboard/Profile.vue`).default,
        meta
    },
    {
        path: "/settings",
        component: require(`@/views/dashboard/Settings.vue`).default,
        meta
    },
    {
        path: "/products",
        component: require(`@/views/dashboard/Products.vue`).default,
        meta: { ...meta, stateModule: "product" }
    },
    {
        path: "/users",
        component: require(`@/views/dashboard/Users.vue`).default,
        meta: { ...meta, stateModule: "user" }
    },
    {
        path: "/roles",
        component: require(`@/views/dashboard/Roles.vue`).default,
        meta: { ...meta, stateModule: "role" }
    },
    {
        path: "/cities",
        component: require(`@/views/dashboard/Cities.vue`).default,
        meta: { ...meta, stateModule: "city" }
    },
    {
        path: "/banks",
        component: require(`@/views/dashboard/Banks.vue`).default,
        meta: { ...meta, stateModule: "bank" }
    },
    {
        path: "/branches",
        component: require(`@/views/dashboard/Branches.vue`).default,
        meta: { ...meta, stateModule: "branch" }
    },
    {
        path: "/merchants",
        component: require(`@/views/dashboard/Merchants.vue`).default,
        meta: { ...meta, stateModule: "merchant" }
    },
    {
        path: "/products",
        component: require(`@/views/dashboard/Products.vue`).default,
        meta: { ...meta, stateModule: "product" }
    },
    {
        path: "/type-merchants",
        component: require(`@/views/dashboard/TypeOfMerchants.vue`).default,
        meta
    },
    {
        path: "/invoices",
        component: require(`@/views/dashboard/invoices/Index.vue`).default,
        meta,
        alias: "/home"
    },
    {
        path: "/invoices-open",
        component: require(`@/views/dashboard/invoices/Open.vue`).default,
        meta
    },
    {
        path: "/invoices/create",
        component: require(`@/views/dashboard/invoices/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/invoices/:id",
        component: require(`@/views/dashboard/invoices/Show.vue`).default,
        meta
    },
    {
        path: "/invoices/update/:id",
        component: require(`@/views/dashboard/invoices/AddAndUpdate.vue`).default,
        meta
    }
];

export default routes;
