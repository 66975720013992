<template>
    <base-crud
        :icon="$t('invoices.transactions.icon')"
        :title="$t('invoices.transactions.title')"
        stateModule="invoiceTransaction"
        :enable-add-option="_hasPermissions('invoice_transactions.store')"
        :enable-update-option="_hasPermissions('invoice_transactions.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
        @added-item="addedItem"
        @updated-item="$emit('updated-item', $event)"
    >
        <template #headerActions v-if="_hasPermissions('invoice_transactions.print')">
            <!-- brn print item -->
            <v-btn
                fab
                small
                color="blue-grey darken-3"
                dark
                class="ms-1"
                :href="serverUrl(`/invoice_transactions/${invoice.id}?key=${$store.state.auth.key}`)"
                target="_blank"
            >
                <v-icon>mdi-printer</v-icon>
            </v-btn>
        </template>

        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.amount')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.amount"
                            outlined
                            :label="$t('attributes.amount')"
                            :error-messages="errors"
                            type="number"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="required|date|before_or_equal:today" :name="$t('attributes.date')" v-slot="{ errors }">
                        <date-field outlined v-model="item.date" :label="$t('attributes.date')" :error-messages="errors"></date-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                        <v-textarea
                            outlined
                            v-model.trim="item.note"
                            :label="$t('attributes.note')"
                            :error-messages="errors"
                            counter
                            :maxlength="500"
                            rows="3"
                            auto-grow
                            clearable
                        ></v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </template>
    </base-crud>
</template>

<script>
export default {
    props: {
        invoice: { type: Object, required: true }
    },

    data: function() {
        return {
            headers: [
                {
                    text: this.$t("attributes.date"),
                    value: "date"
                },
                {
                    text: this.$t("attributes.amount"),
                    value: "amount"
                },
                {
                    text: this.$t("attributes.remaining"),
                    value: "remaining"
                },
                {
                    text: this.$t("attributes.note"),
                    value: "note"
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.updated_at"), value: "updated_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { invoice_id: this.invoice.id, date: _getCurrentDate() }
        };
    },

    created() {
        this.$store.state.app.loading = true;

        this.$store
            .dispatch(`invoiceTransaction/fetchAll`, { invoice_id: this.invoice.id })
            .then(() => {
                this.setItemsThatCanEdit();
            })

            .finally(() => {
                this.$store.state.app.loading = false;
            });
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        serverUrl(link) {
            return serverUrl(link);
        },

        /**
         * Set last item (max id) only that can be edit
         */
        setItemsThatCanEdit() {
            let items = this.$store.state.invoiceTransaction.items;
            let maxId = Math.max.apply(
                null,
                items.data.map(o => o.id)
            );
            items.data.forEach(e => {
                e.disallowEdit = e.id != maxId; // to disallow edit item in datatable
            });
            this.$store.state.invoiceTransaction.items = { ...items }; // to refresh data in datatable
        },

        addedItem(e) {
            this.$emit("added-item", e);
            this.setItemsThatCanEdit();
        }
    }
};
</script>
