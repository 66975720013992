<template>
    <base-crud
        :icon="$t('merchants.types.icon')"
        :title="$t('merchants.types.title')"
        stateModule="typeMerchant"
        :enable-add-option="_hasPermissions('type_of_merchants.store')"
        :enable-update-option="_hasPermissions('type_of_merchants.update')"
        :headers="headers"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12">
                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <v-switch v-model="item.active" :label="$t('attributes.state')"></v-switch>
                </v-col>
            </v-row>
        </template>

        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.state" :label="$t('attributes.state')" clearable />
                </v-col>
            </v-row>
        </template>
    </base-crud>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                {
                    text: this.$t("attributes.name"),
                    value: "name",
                    filter: value => {
                        return filterContains(value, this.search.name);
                    }
                },
                {
                    text: this.$t("attributes.state"),
                    value: "active",
                    filter: value => _dtFilterStatus(value, this.search.state)
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
