<template>
    <base-crud
        :icon="$t('products.icon')"
        :title="$t('products.title')"
        stateModule="product"
        :enable-add-option="_hasPermissions('products.store')"
        :enable-update-option="_hasPermissions('products.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12">
                    <validation-provider rules="required|min:3|max:64" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="64"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.merchant')" v-slot="{ errors }">
                        <merchants-field v-model.trim="item.merchant_id" :error-messages="errors"></merchants-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <v-switch v-model="item.active" :label="$t('attributes.state')"></v-switch>
                </v-col>
            </v-row>
        </template>

        <!-- Start search section -->
        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <merchants-field v-model.trim="search.merchant" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.state" :label="$t('attributes.state')" clearable />
                </v-col>
            </v-row>
        </template>
        <!--/ End search section -->
    </base-crud>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                { text: this.$t("attributes.name"), value: "name", filter: value => filterContains(value, this.search.name) },
                {
                    text: this.$t("attributes.merchant"),
                    value: "merchant.name",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(item.merchant.id, this.search.merchant);
                    }
                },
                { text: this.$t("attributes.state"), value: "active", filter: value => _dtFilterStatus(value, this.search.state) },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { active: true }
        };
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
