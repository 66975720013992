/**
 * This file to set routes for guests (Unauthenticated users)
 */

/**
 * Properties for these routes
 */
const meta = {
    requiresGuest: true /* route requires authentication */,
    layout: "empty-layout" /* route uses dashboard-layout */
};

const routes = [
    {
        path: "/login",
        component: require("@/views/dashboard/Login.vue").default,
        meta
    }
];

export default routes;
