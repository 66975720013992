<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('invoices.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">
                        {{ invoice.id }}
                    </div>

                    <div>
                        <!-- btn edit item  -->
                        <v-btn v-if="canEditInvoice" fab small dark :color="$store.state.app.color" :to="`/invoices/update/${invoice.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>

                        <!-- brn print item -->
                        <v-btn
                            fab
                            small
                            color="blue-grey darken-3"
                            dark
                            class="ms-1"
                            :href="serverUrl(`/invoices/${invoice.id}?key=${$store.state.auth.key}`)"
                            target="_blank"
                        >
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <v-tabs v-model="tabs" fixed-tabs :color="$store.state.app.color">
                    <v-tab href="#info" class="primary--text">
                        {{ $t("attributes.information") }}
                    </v-tab>
                    <v-tab href="#items" class="primary--text">
                        {{ $t("attributes.items") }}
                    </v-tab>
                    <v-tab href="#gurantors" class="primary--text">
                        {{ $t("attributes.gurantors") }}
                    </v-tab>
                    <v-tab href="#transactions" class="primary--text">
                        {{ $t("attributes.transactions") }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs">
                    <!-- Start information tab -->
                    <v-tab-item value="info">
                        <div class="pa-5">
                            <!-- Start client information -->
                            <v-row>
                                <v-col cols="12">
                                    <h3>{{ $t("attributes.client_information") }}</h3>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field v-model.trim="invoice.client.name" :label="$t('attributes.name')" readonly></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.client.code" :label="$t('attributes.code')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.client.phone" :label="$t('attributes.phone')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.client.nid" :label="$t('attributes.nid')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        v-model.trim="invoice.client.branch.bank.name"
                                        :label="$t('attributes.bank')"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.client.branch.name" :label="$t('attributes.branch')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        v-model.trim="invoice.client.account"
                                        :label="$t('attributes.account_number')"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- End client information -->

                            <v-divider></v-divider>

                            <!-- Start invoice information -->
                            <v-row>
                                <v-col cols="12">
                                    <h3>{{ $t("attributes.invoice_information") }}</h3>
                                </v-col>

                                <v-col cols="12" lg="6">
                                    <v-text-field v-model.trim="invoice.merchant.name" :label="$t('attributes.merchant')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        v-model.trim="invoice.original_amount"
                                        :label="$t('attributes.original_amount')"
                                        readonly
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.amount" :label="$t('attributes.amount')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.count_months" :label="$t('attributes.count_months')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        v-model.trim="invoice.installment_value"
                                        :label="$t('attributes.installment_value')"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.remaining" :label="$t('attributes.remaining')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field v-model.trim="invoice.date" :label="$t('attributes.date')" readonly></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" lg="3" v-if="invoice.client.file_url">
                                    <v-btn color="info" block dark :href="invoice.client.file_url" target="_blank">
                                        {{ $t("attributes.file") }}
                                        <v-icon>mdi-file-pdf</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-textarea v-model.trim="invoice.note" :label="$t('attributes.note')" readonly rows="2" auto-grow></v-textarea>
                                </v-col>
                            </v-row>
                            <!-- End invoice information -->

                            <v-divider></v-divider>

                            <v-row>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field :value="invoice.created_at" :label="$t('attributes.created_at')" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field :value="invoice.updated_at" :label="$t('attributes.updated_at')" readonly></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                    <!-- End information tab -->

                    <!-- Start items tab -->
                    <v-tab-item value="items">
                        <invoice-items :invoice="invoice" />
                    </v-tab-item>
                    <!-- End items tab -->

                    <!-- Start gurantors tab -->
                    <v-tab-item value="gurantors">
                        <invoice-gurantors :invoice="invoice" />
                    </v-tab-item>
                    <!-- End gurantors tab -->

                    <!-- Start transactions tab -->
                    <v-tab-item value="transactions">
                        <v-row class="pa-5" justify="space-around">
                            <v-col cols="auto">
                                <h3>
                                    <bdi>قيمة الفاتورة : </bdi> <code>{{ invoice.amount }}</code>
                                </h3>
                            </v-col>
                            <v-col cols="auto">
                                <h3>
                                    <bdi>قيمة المتبقية : </bdi> <code>{{ invoice.remaining }}</code>
                                </h3>
                            </v-col>
                        </v-row>

                        <invoice-transactions :invoice="invoice" @added-item="reloadInvoice" @updated-item="reloadInvoice" />
                    </v-tab-item>
                    <!-- End transactions tab -->
                </v-tabs-items>

                <v-divider></v-divider>
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
import InvoiceItems from "./Items.vue";
import InvoiceGurantors from "./Gurantors.vue";
import invoiceTransactions from "./Transactions.vue";

export default {
    components: { InvoiceItems, InvoiceGurantors, invoiceTransactions },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;

        store.dispatch("invoice/fetchById", id).then(() => {
            next();
        });
    },

    created() {
        //this.invoice.social_status.name = `${this.invoice.social_status.name_en} - ${this.invoice.social_status.name_ar}`;
    },

    data: function() {
        return {
            tabs: null
        };
    },

    computed: {
        ...mapState({
            invoice: state => state.invoice.item
        }),

        canEditInvoice() {
            return this.invoice.remaining == this.invoice.amount;
        }
    },

    methods: {
        serverUrl(link) {
            return serverUrl(link);
        },

        reloadInvoice() {
            this.$store.state.app.loading = true;
            this.$store.dispatch("invoice/fetchById", this.invoice.id).finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>

<style scoped>
.v-tab {
    max-width: 100% !important;
    font-size: 1.1em !important;
    letter-spacing: 0 !important;
}
</style>
