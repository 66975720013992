<template>
    <v-alert :value="hasErrors" type="error" dismissible>
        <ul>
            <li v-if="typeof errors == 'string'">
                {{ errors }}
            </li>
            <template v-else>
                <li v-for="(error, key) in errors" :key="`error${key}`">
                    <template v-if="!Array.isArray(error)">{{ error }}</template>

                    <ul class="list-unstyled" v-else v-for="(item, k) in error" :key="`subError${k}`">
                        <li>{{ item }}</li>
                    </ul>
                </li>
            </template>
        </ul>
    </v-alert>
</template>

<script>
export default {
    name: "HasErrors",

    props: {
        errors: { type: [Object, Array, String], required: true }
    },

    computed: {
        hasErrors() {
            if (typeof this.errors == String) {
                return this.errors;
            }
            return Object.keys(this.errors).length > 0;
        }
    }
};
</script>
