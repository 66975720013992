<template>
    <base-crud
        :icon="$t('invoices.items.icon')"
        :title="$t('invoices.items.title')"
        stateModule="invoiceItem"
        :enable-add-option="_hasPermissions('invoice_items.store')"
        :enable-update-option="_hasPermissions('invoice_items.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.product')" v-slot="{ errors }">
                        <products-field
                            v-model.trim="item.product_id"
                            :merchant="invoice.merchant_id"
                            outlined
                            :error-messages="errors"
                        ></products-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="required|min_value:1" :name="$t('attributes.count')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.count"
                            outlined
                            :label="$t('attributes.count')"
                            :error-messages="errors"
                            counter
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                        <v-textarea
                            outlined
                            v-model.trim="item.note"
                            :label="$t('attributes.note')"
                            :error-messages="errors"
                            counter
                            :maxlength="500"
                            rows="3"
                            auto-grow
                            clearable
                        ></v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </template>
    </base-crud>
</template>

<script>
export default {
    props: {
        invoice: { type: Object, required: true }
    },

    data: function() {
        return {
            headers: [
                {
                    text: this.$t("attributes.product"),
                    value: "product.name"
                },
                {
                    text: this.$t("attributes.count"),
                    value: "count"
                },
                {
                    text: this.$t("attributes.note"),
                    value: "note"
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { invoice_id: this.invoice.id }
        };
    },

    created() {
        this.$store.state.app.loading = true;

        this.$store.dispatch(`invoiceItem/fetchAll`, { invoice_id: this.invoice.id }).finally(() => {
            this.$store.state.app.loading = false;
        });
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
