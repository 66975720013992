import store from "@/store/index";

/**
 *  Check if current user has access permissions to do something
 *
 * @param {String|Array} permissions
 * @param {Boolean}
 */
window._hasPermissions = function(permissions) {
    if (Array.isArray(permissions)) {
        return permissions.some(p => store.state.auth.user.permissions.includes(p));
    }
    return store.state.auth.user.permissions.includes(permissions);
};

/**
 *  Format datetime
 *
 * @param {String} date
 * @param {Boolean}
 */
window._datetimeFormat = function(date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD hh:mm a");
};

/**
 *  Format date
 *
 * @param {String} date
 * @param {Boolean}
 */
window._dateFormat = function(date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
};

/**
 *  get Current date
 */
window._getCurrentDate = function() {
    return moment().format("YYYY-MM-DD");
};

/**
 *  get Current date time
 */
window._getCurrentDateTime = function() {
    return moment().format("YYYY-MM-DD hh:mm a");
};

/**
 * This function to make search if string continas specific string with ignore sensitive case
 * I use this in filter in datatable
 */
window.filterContains = function(value, search) {
    if (!search) return true;

    if (!value) return false;

    return value
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase());
};

/**
 * This function to make search by statuse (StatusesField)
 * I use this in filter in datatable
 */
window._dtFilterStatus = function(value, search) {
    if (search == true || search == false) {
        return value == search;
    }
    return true;
};

/**
 * This function to make search through check if value equal another value
 * I use this in filter in datatable
 */
window._dtFilterEqual = function(value, search) {
    if (!search || !value) return true;
    return value == search;
};

/**
 * This function to make search through check if value exists in values
 * I use this in filter in datatable
 */
window._dtFilterArrayEqual = function(value, search) {
    if (!search || !value) return true;
    return value.includes(search);
};

/**
 * This function to make link to server
 * (we need it in when back-end not equal to front-end url)
 */
window.serverUrl = function(link) {
    return new URL(link, store.state.app.serverUrl).href;
};
