<template>
    <!-- Start navbar -->
    <v-app-bar app :color="$store.state.app.color" dark>
        <v-app-bar-nav-icon @click.stop="sidebar.show = !sidebar.show"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ $t("app_name") }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <v-btn icon>
            <v-icon>mdi-account</v-icon>
        </v-btn> -->

        <!-- Start user menu -->
        <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list width="250" nav dense>
                <v-list-item v-for="link in links" :key="`navMenu${link.label}`">
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ link.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu> -->
        <!-- End user menu -->
    </v-app-bar>
    <!--/ End navbar -->
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "DashboardNavbar",

    data: function() {
        return {
            links: [
                { label: "Profile", icon: "mdi-account", url: "/profile" },
                { label: "Settings", icon: "mdi-cog", url: "/settings" },
                { label: "Logout", icon: "mdi-logout", url: "/logout" }
            ]
        };
    },

    computed: {
        ...mapState("app", {
            sidebar: state => state.layouts.dashboard.sidebar
        })
    }
};
</script>
