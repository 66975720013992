import Vue from "vue";
import VueRouter from "vue-router";
import GuestRoutes from "./guest";
import DashboardRoutes from "./dashboard";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
    ...GuestRoutes,
    ...DashboardRoutes,
    {
        path: "*",
        component: require(`../views/errors/404.vue`).default
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

/**
 * Before enter any view(page) show loading overlay
 * Check all middlewares here
 * Check if route requires permissiantions
 * for exmaple route requries authentication or requries guest user
 */
router.beforeEach((to, _from, next) => {
    store.state.app.loading = true;

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters["auth/isLogged"]) {
            return next("/login");
        }

        if (to.meta.stateModule) {
            // if route needs to load data , then load it before enter to page
            return store.dispatch(`${to.meta.stateModule}/fetchAll`).then(() => {
                next();
            });
        }
    }

    if (to.matched.some(record => record.meta.requiresGuest) && store.getters["auth/isLogged"]) {
        return next("/home");
    }

    next();
});

/**
 * After enter any view(page) hide loading overlay
 */

// eslint-disable-next-line
router.afterEach((_to, _from) => {
    store.state.app.loading = false;
});

export default router;
