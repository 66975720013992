import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/lang/en";
import ar from "@/lang/ar";
import moment from "moment";

Vue.use(VueI18n);

const messages = {
    en,
    ar
};

/* set (Arabic) as default language */
let locale = "ar";

/* if user changed default language then set it as default */
if (localStorage.getItem("lang")) {
    locale = localStorage.getItem("lang");
}

const i18n = new VueI18n({
    locale,
    fallbackLocale: "en",
    messages
});

/* this function to change language of app  */
i18n.changeLanguageTo = lang => {
    /* if selected language doesn't exist then set locale value equals fall back locale */
    if (!i18n.availableLocales.includes(lang)) {
        lang = i18n.fallbackLocale;
    }

    i18n.locale = lang;
    localStorage.setItem("lang", lang);

    moment.locale(lang == "ar" ? "ar-Ly" : "en");

    if (i18n.t("direction") === "rtl") {
        document.body.classList.add("rtl");
    } else {
        document.body.classList.remove("rtl");
    }
};

i18n.changeLanguageTo(locale);

export default i18n;
