import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import auth from "@/store/modules/auth";
import statistic from "@/store/modules/statistic";
import helpers from "@/plugins/utils/helpers";
import setting from "@/store/modules/setting";
import permission from "@/store/modules/permission";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        //product,
        setting,
        permission,
        product: helpers.generateStoreModule("products"),
        merchant: helpers.generateStoreModule("merchants"),
        typeMerchant: helpers.generateStoreModule("type_of_merchants"),
        city: helpers.generateStoreModule("cities"),
        bank: helpers.generateStoreModule("banks"),
        branch: helpers.generateStoreModule("branches"),
        role: helpers.generateStoreModule("roles"),
        user: helpers.generateStoreModule("users"),
        invoice: helpers.generateStoreAdvancedModule("invoices"),
        invoiceItem: helpers.generateStoreModule("invoice_items"),
        invoiceTransaction: helpers.generateStoreModule("invoice_transactions"),
        gurantor: helpers.generateStoreModule("gurantors"),
        auth,
        statistic
    }
});
