<template>
    <base-card class="my-4 mx-auto" :icon="$t('settings.icon')">
        <template #title>
            <div class="d-flex justify-space-between">
                <div class="headline">{{ $t("settings.title") }}</div>
            </div>
        </template>

        <template #content>
            <v-divider></v-divider>

            <validation-observer ref="settingForm" v-slot="{ handleSubmit }">
                <v-form class="px-5" @submit.prevent="handleSubmit(updateSettings)">
                    <!-- show errors if there is errors when add/update item -->
                    <has-errors :errors="errors"></has-errors>

                    <v-row class="pt-5">
                        <v-col cols="12" md="6">
                            <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="setting.phone"
                                    :label="$t('attributes.phone')"
                                    :error-messages="errors"
                                    counter
                                    outlined
                                    :maxlength="32"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider :name="$t('attributes.phone')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="setting.phone2"
                                    :label="`${$t('attributes.phone')}2`"
                                    :error-messages="errors"
                                    counter
                                    outlined
                                    :maxlength="32"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider rules="required" :name="$t('attributes.email')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="setting.email"
                                    :label="$t('attributes.email')"
                                    :error-messages="errors"
                                    counter
                                    outlined
                                    :maxlength="75"
                                    type="email"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider rules="required" :name="$t('attributes.address')" v-slot="{ errors }">
                                <v-text-field
                                    v-model.trim="setting.address"
                                    :label="$t('attributes.address')"
                                    :error-messages="errors"
                                    counter
                                    outlined
                                    :maxlength="32"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-img max-height="150" contain :src="setting.logo_url" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-file-input
                                v-model="setting.logo"
                                :label="$t('attributes.logo')"
                                accept="image/*"
                                show-size
                                counter
                                outlined
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <div class="text-end px-5 py-3">
                        <v-btn color="info" min-width="150px" type="submit">{{ $t("options.update") }}</v-btn>
                    </div>
                </v-form>
            </validation-observer>
        </template>
    </base-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        store.dispatch("setting/fetch").then(() => next());
    },

    data: function() {
        return {
            setting: {},
            errors: {}
        };
    },
    computed: {
        ...mapState({
            data: state => state.setting.item
        })
    },

    created() {
        this.setting = Object.assign(this.data);
    },

    methods: {
        ...mapActions("setting", ["update"]),

        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        /**
         * Add data to server
         */
        updateSettings() {
            this.errors = {};
            this.$store.state.app.loading = true;

            let data = new FormData();
            for (let e in this.setting) {
                data.set(e, this.setting[e]);
            }

            this.update(data)
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    // to reload image from server
                    this.setting.logo_url = this.setting.logo_url + "?" + Math.random();
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>
