<template>
    <v-sheet class="mb-10" rounded elevation="1">
        <v-row no-gutters align="center" justify="space-between">
            <v-col>
                <v-breadcrumbs :items="items" large></v-breadcrumbs>
            </v-col>
            <v-col cols="auto" class="px-4" v-if="addLink">
                <v-btn fab small :color="$store.state.app.color" :to="addLink"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
        </v-row>
        <!-- btn add new item  -->
    </v-sheet>
</template>

<script>
export default {
    name: "Breadcrumbs",

    props: {
        items: [Array],
        addLink: { type: String, default: "" }
    }
};
</script>
