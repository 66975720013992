<template>
    <base-crud
        :icon="$t('invoices.gurantors.icon')"
        :title="$t('invoices.gurantors.title')"
        stateModule="gurantor"
        :enable-add-option="_hasPermissions('gurantors.store')"
        :enable-update-option="_hasPermissions('gurantors.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            outlined
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="64"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.phone"
                            outlined
                            :label="$t('attributes.phone')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.nid')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.nid"
                            outlined
                            :label="$t('attributes.nid')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.bank')" v-slot="{ errors }">
                        <banks-field outlined v-model="item.bank" :error-messages="errors" @input="item.branch_id = null" />
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="required" :name="$t('attributes.branch')" v-slot="{ errors }">
                        <branches-field outlined v-model="item.branch_id" :error-messages="errors" :bank="item.bank" />
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="required|min:6|max:32" :name="$t('attributes.account_number')" v-slot="{ errors }">
                        <v-text-field
                            outlined
                            v-model.trim="item.account"
                            :label="$t('attributes.account_number')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                        <v-textarea
                            outlined
                            v-model.trim="item.note"
                            :label="$t('attributes.note')"
                            :error-messages="errors"
                            counter
                            :maxlength="500"
                            rows="3"
                            auto-grow
                        ></v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </template>
    </base-crud>
</template>

<script>
export default {
    props: {
        invoice: { type: Object, required: true }
    },

    data: function() {
        return {
            headers: [
                {
                    text: this.$t("attributes.name"),
                    value: "name"
                },
                {
                    text: this.$t("attributes.phone"),
                    value: "phone"
                },
                {
                    text: this.$t("attributes.nid"),
                    value: "nid"
                },
                {
                    text: this.$t("attributes.bank"),
                    value: "branch.bank.name"
                },
                {
                    text: this.$t("attributes.branch"),
                    value: "bank.name"
                },
                {
                    text: this.$t("attributes.account_number"),
                    value: "account"
                },
                {
                    text: this.$t("attributes.note"),
                    value: "note"
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { invoice_id: this.invoice.id, client_id: this.invoice.client_id }
        };
    },

    created() {
        this.$store.state.app.loading = true;

        this.$store.dispatch(`gurantor/fetchAll`, { invoice_id: this.invoice.id }).finally(() => {
            this.$store.state.app.loading = false;
        });
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
