<template>
    <base-crud
        :icon="$t('merchants.icon')"
        :title="$t('merchants.title')"
        stateModule="merchant"
        :enable-add-option="_hasPermissions('merchants.store')"
        :enable-update-option="_hasPermissions('merchants.update')"
        :headers="headers"
        :default-valus-for-add="defaultValusForAdd"
        :add-edit-form-width="1024"
        :files="['file']"
        :booleans="['active']"
        :skip-properties="['city', 'type']"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.type_of_merchant')" v-slot="{ errors }">
                        <merchants-types-field v-model.trim="item.type_of_merchant_id" :error-messages="errors"></merchants-types-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.city')" v-slot="{ errors }">
                        <cities-field v-model.trim="item.city_id" :error-messages="errors" />
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:64" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="64"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:64" :name="$t('attributes.owner')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.owner"
                            :label="$t('attributes.owner')"
                            :error-messages="errors"
                            counter
                            :maxlength="64"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.phone"
                            :label="$t('attributes.phone')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-switch v-model="item.active" :label="$t('attributes.state')"></v-switch>
                </v-col>

                <v-col cols="12">
                    <validation-provider rules="size:20480|ext:pdf" :name="$t('attributes.file')" v-slot="{ errors }">
                        <v-file-input v-model="item.file" :label="$t('attributes.file')" :error-messages="errors" show-size counter></v-file-input>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="max:1000" :name="$t('attributes.address')" v-slot="{ errors }">
                        <v-textarea
                            v-model.trim="item.address"
                            :label="$t('attributes.address')"
                            :error-messages="errors"
                            counter
                            :maxlength="1000"
                            rows="2"
                            auto-grow
                            clearable
                        ></v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </template>

        <!-- Start Search section-->
        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.id" :label="$t('attributes.id')" clearable type="number" />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <merchants-types-field v-model.trim="search.type" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <cities-field v-model.trim="search.city" :chips="false" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.state" :label="$t('attributes.state')" clearable />
                </v-col>
            </v-row>
        </template>
        <!--/ End Search section-->

        <!-- datatable actions -->
        <template #itemActions="{ item }">
            <v-btn v-if="item.file_url" x-small fab color="secondary" class="ms-1" dark :href="item.file_url" target="_blank">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </template>
        <!--/ datatable actions -->
    </base-crud>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                { text: this.$t("attributes.id"), value: "id", filter: value => _dtFilterEqual(value, this.search.id) },
                { text: this.$t("attributes.name"), value: "name", filter: value => filterContains(value, this.search.name) },
                {
                    text: this.$t("attributes.city"),
                    value: "city.name",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(item.city.id, this.search.city);
                    }
                },
                {
                    text: this.$t("attributes.type"),
                    value: "type.name",
                    filter: (value, search, item) => {
                        return _dtFilterEqual(item.type.id, this.search.type);
                    }
                },
                { text: this.$t("attributes.owner"), value: "owner", filter: value => filterContains(value, this.search.owner) },
                { text: this.$t("attributes.phone"), value: "phone", filter: value => filterContains(value, this.search.phone) },
                { text: this.$t("attributes.address"), value: "address", filter: value => filterContains(value, this.search.address) },
                { text: this.$t("attributes.state"), value: "active", filter: value => _dtFilterStatus(value, this.search.state) },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 120 }
            ],
            defaultValusForAdd: { active: true }
        };
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
