<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('invoices.open.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("invoices.open.title") }}</div>
                </div>

                <!-- brn print item -->
                <v-btn
                    v-if="_hasPermissions('invoices.print_open')"
                    fab
                    small
                    color="blue-grey darken-3"
                    dark
                    class="ms-1"
                    :href="getPrintLink()"
                    target="_blank"
                >
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selectedHeaders" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                <component
                                    v-if="typeof field === 'object'"
                                    :is="field.component"
                                    v-model.trim="search[field.name]"
                                    :label="field.label"
                                    v-bind="field.props"
                                />
                                <v-text-field v-else v-model.trim="search[field]" :label="$t(`attributes.${field}`)" maxlength="32" clearable />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <banks-field v-model.trim="search.client_bank" clearable @input="search.client_branch = null" />
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <branches-field v-model.trim="search.client_branch" :bank="search.client_bank" clearable />
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="selectedHeaders"
                    :items="data.data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/invoices/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.data || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;
    params.open = true; // to load open invoices only

    store.dispatch("invoice/fetchAll", { page, ...params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: [
                "id",
                { component: "v-text-field", name: "client_code", label: this.$t("attributes.code"), props: { clearable: true } },
                { component: "v-text-field", name: "client_name", label: this.$t("attributes.name"), props: { clearable: true } },
                { component: "v-text-field", name: "client_phone", label: this.$t("attributes.phone"), props: { clearable: true } },
                { component: "v-text-field", name: "client_account", label: this.$t("attributes.account_number"), props: { clearable: true } },
                { component: "v-text-field", name: "client_nid", label: this.$t("attributes.nid"), props: { clearable: true } },
                //{ component: "banks-field", name: "client[bank]", label: this.$t("attributes.bank") },
                //{ component: "branches-field", name: "client[branch]", label: this.$t("attributes.branch"), props: { clearable: true } },
                { component: "merchants-field", name: "merchant_id", label: this.$t("attributes.merchant"), props: { clearable: true } },
                "amount",
                "original_amount",
                "installment_value",
                "remaining",
                "count_months",
                { component: "date-field", name: "date_from", label: this.$t("search.date_from"), props: { clearable: true } },
                { component: "date-field", name: "date_to", label: this.$t("search.date_to"), props: { clearable: true } },
                { component: "date-field", name: "created_at_from", label: this.$t("search.created_at_from"), props: { clearable: true } },
                { component: "date-field", name: "created_at_to", label: this.$t("search.created_at_to"), props: { clearable: true } }
            ],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.customer"), value: "client.name" },
                { text: this.$t("attributes.code"), value: "client.code" },
                { text: this.$t("attributes.nid"), value: "client.nid" },
                { text: this.$t("attributes.phone"), value: "client.phone" },
                { text: this.$t("attributes.account_number"), value: "client.account" },
                { text: this.$t("attributes.merchant"), value: "merchant.name" },
                { text: this.$t("attributes.original_amount"), value: "original_amount" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.installment_value"), value: "installment_value" },
                { text: this.$t("attributes.count_months"), value: "count_months" },
                { text: this.$t("attributes.remaining"), value: "remaining" },
                { text: this.$t("attributes.date"), value: "date" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.updated_at"), value: "updated_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ],
            selectedHeaders: []
        };
    },

    computed: {
        ...mapState({
            data: state => state.invoice.items
        })
    },

    created() {
        this.selectedHeaders = this.headers.filter(
            i =>
                !["client.nid", "client.phone", "client.account", "count_months", "installment_value", "note", "created_at", "updated_at"].includes(
                    i.value
                )
        );
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        rowClass(item) {
            return null;
            //return (!item.activated_at && "green white--text") || (!item.active && "red white--text");
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        },

        getPrintLink() {
            let p = new URLSearchParams({
                key: this.$store.state.auth.key,
                ...this.search
            }).toString();
            return serverUrl(`/invoices/open?${p}`).toString();
        }
    }
};
</script>
